import request from '@/utils/request'


// 查询订单操作日志列表
export function listOperateLog(query) {
  return request({
    url: '/order/operate-log/list',
    method: 'get',
    params: query
  })
}

// 查询订单操作日志分页
export function pageOperateLog(query) {
  return request({
    url: '/order/operate-log/page',
    method: 'get',
    params: query
  })
}

// 查询订单操作日志详细
export function getOperateLog(data) {
  return request({
    url: '/order/operate-log/detail',
    method: 'get',
    params: data
  })
}

// 新增订单操作日志
export function addOperateLog(data) {
  return request({
    url: '/order/operate-log/add',
    method: 'post',
    data: data
  })
}

// 修改订单操作日志
export function updateOperateLog(data) {
  return request({
    url: '/order/operate-log/edit',
    method: 'post',
    data: data
  })
}

// 删除订单操作日志
export function delOperateLog(data) {
  return request({
    url: '/order/operate-log/delete',
    method: 'post',
    data: data
  })
}
